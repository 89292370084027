import React from "react";
import XIcon from './Images/XLogoWhite.jpg'
import GithubIcon from './Images/github-mark-white.jpg'
import LinkedInIcon from './Images/In-Blue-96.jpg'
import './styles/generalwebstyle.css'


function Copyright() {
    return (
        <div className={"copyright"}>
            <p>© 2025 Tomos Jones. All rights reserved.</p>
            </div>
        
    )
}

export default Copyright;