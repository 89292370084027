import React from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import './components/styles/generalwebstyle.css'
import './components/styles/fontstyle.css'
import Header from './components/Header';
import About from './components/About';
import HomePage from './components/homepage';
import Skills from "./components/Skills";
import Navbar from "./components/navbar";
import BlogComponent from "./components/BlogComponent"
import SimpleChatApp from "./components/projects/SimpleChatApp"
import ProjectCards from "./components/projectcards";
import FullBlogPostPage from "./components/FullBlogPostPage";
import {AnimatePresence} from "framer-motion";
import FadeAnimation from "./components/FadeAnimation";
import ProjectDetail from "./components/ProjectDetail";
import SocialLinks from "./components/social-links";
import Copyright from "./components/Copyright";



function App() {

  return (
          <Router>
              <div>

                  <div className="blogErrorMessage">
                      <h1>📢Important Site Update!</h1>
                      <p>I'm having some problems with the hosting platform of my blogs, so I will be moving them to a self hosted system, I really apologise about this!!</p>
                      <p>
                          This will take some time so for now here are all of the blog posts on the default site!:{' '}
                          <a href="https://toms-blogs.ghost.io/" target="_blank"
                             style={{color: '#007bff', textDecoration: 'underline'}}>
                              Visit the Default Blog Site
                          </a>.
                      </p>
                      <p>Thank you for understanding!</p>
                  </div>


                  <Header/>
                  <Navbar/>
                  <AnimatePresence mode="wait">
                      <FadeAnimation>
                          <Routes>
                              {/*Routes for pages*/}
                              <Route path="/" element={<HomePage/>}/>
                              <Route path="/about" element={<About/>}/>
                              <Route path="/projects" element={<ProjectCards/>}/>
                              <Route path="/skills" element={<Skills/>}/>
                              {/*
                      <Route path="/projects/cauldronchaos" element={<BlogComponent/>}/>
*/}
                              <Route path="/projects/simplechatapp" element={<SimpleChatApp/>}/>

                              {/*Routes For Blogposts! */}
                              <Route path="/projects/:projectSlug/details" element={<ProjectDetail/>}/>
                              <Route path="/projects/:projectSlug/blog" element={<BlogComponent/>}/>
                              <Route path="/projects/:projectSlug/blog/:slug" element={<FullBlogPostPage/>}/>
                          </Routes>
                      </FadeAnimation>
                  </AnimatePresence>
              </div>
              <SocialLinks/>
              <Copyright/>
          </Router>
  );
}

export default App;
